.html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
.body {
    margin: 0;
}
.a {
    background-color: transparent;
}
a:active,
a:hover {
    outline: 0;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.col-12{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}
.col-12 {
    float: left;
    width: 100%;
}
.container:before,
.container:after,
.row:before,
.row:after {
    content: " ";
    display: table;
}
.container:after,
.row:after {
    clear: both;
}
.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
#error-img-top{
    margin-top: 20px;
}
#error-title{
    font-size: 120px !important;
    text-align: center;
    margin: 0 !important;
    padding: 0 !important;
    color: #4d4d4d;
    line-height: 110px;
}
#error-description{
    color: #8a8a8a;
    text-align: center;
    font-size: 18px !important;
    margin: 0 !important;
}
#error-back{
    text-align: center;
    margin: 20px 0 20px 0 !important;
}
#error-back-img{
    display: inline !important;
}
#error-back-text{
    color: #208eca;
    text-decoration: underline;
    font-size: 16px !important;
}
#error-bottom{
    text-align: center;
}
#error-link-bottom{
    color: #208eca;
    font-size: 14px !important;
    text-decoration: none;
}